.ed-list {
    font-weight: 400 !important;
    > div {
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
    }
    .ibox {
        width: 3.5rem;
    }
}

.table-invitee-header {
    .seat-no {
        width: 48px;
    }
}
.table-invitee {
    .seat-no {
        width: 48px;
        height: 48px;
    }
}

.invitee-lists {
    .card {
        .collapsed {
            .fa-chevron-down {
                display: inline-block !important;
            }
            .fa-chevron-up {
                display: none !important;
            }
        }
    }
    .card:first-child {
        border-top: 1px solid $gray-300 !important;
    }
}

.table-strage {
    max-width: 100%;
    width: 440px;
    letter-spacing: 2px;
}
.table-strage-img {
    max-width: 500px;
}

.sortable {
    .form-control {
        padding-left: 1.2rem;
        padding-right: 1.2rem;

        @include placeholder {
            opacity: 0.7;
        }
    }
}

#max-free-tickets-alert {
    position: fixed;
    z-index: 1000;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
}

@include media-breakpoint-up(lg) {
    .ed-list {
        font-weight: 900 !important;
        > div {
            border-bottom: 1px solid $gray-300;
            padding-bottom: 1.5rem;
            margin-bottom: 1.5rem;
        }
        .ibox {
            color: $secondary;
        }
    }
}

@include media-breakpoint-up(xl) {
    .sortable {
        .form-control {
            @include placeholder {
                color: transparent;
            }
        }
    }
}

@include media-breakpoint-up(xxl) {
    .table-invitee-header,
    .table-invitee {
        .seat-no {
            width: 60px;
        }
    }
}

@media (max-width: 360px) {
    .table-invitee-header,
    .table-invitee {
        .seat-no {
            width: 40px;
            height: 30px;
        }
    }
}
