.filter-label {
    font-size: 1.4rem;
    letter-spacing: 1px;
}

.eventList-card{
    .card-date{
        top: 1rem;
        left: 1rem;
        font-size: 1.7rem;
    }
    .card-sub-title {
        font-size: 1.7rem;
    }
    .card-text{
        line-height: 1.5;
    }
}

@include media-breakpoint-up(lg) {
    .filter-field {
        width: 190px;
    }
}

@include media-breakpoint-up(xl) {
    .filter-field {
        width: 240px;
    }
}